.blazepodIcon {
  position: relative;
  width: 5em;
}

.logoContainer1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.errorsHazzard1 {
  position: relative;
  width: 94px;
  height: 94px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.trainingExcellence {
  width: 100%;
  position: relative;
  font-size: 26px;
  line-height: 32px;
  display: inline-block;
  font-family: 'Slussen Condensed Bold';
  color: #1a3b66;
  text-align: center;
  width: 315px;
  margin-bottom: 10px;
}
.trainingExcellence1 {
  position: relative;
  font-size: 16px;
  line-height: 20px;
  font-family: Satoshi;
  color: #2b4a75;
  text-align: center;
  display: inline-block;
  width: 315px;
}
.leaderboardsInner {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


