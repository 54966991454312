@use "./components/common/vars";

body {
  	margin: 0; line-height: normal;
}

@font-face {
  font-family: Satoshi;
  src: url(./assets/fonts/Satoshi/Satoshi-Regular.otf);
}

@font-face {
  font-family: Outfit;
  src: url(./assets/fonts/Outfit/Outfit-Regular.ttf);
}
@font-face {
  font-family: 'Outfit Light';
  src: url(./assets/fonts/Outfit/Outfit-Light.ttf);
}

@font-face {
  font-family: 'Outfit Bold';
  src: url(./assets/fonts/Outfit/Outfit-Bold.ttf);
}

@font-face {
  font-family: 'Outfit SemiBold';
  src: url(./assets/fonts/Outfit/Outfit-SemiBold.ttf);
}

@font-face {
  font-family: 'Outfit Medium';
  src: url(./assets/fonts/Outfit/Outfit-Medium.ttf);
}

@font-face {
  font-family: 'Outfit Thin';
  src: url(./assets/fonts/Outfit/Outfit-Thin.ttf);
}

@font-face {
  font-family: 'Satoshi Bold';
  src: url(./assets/fonts/Satoshi/Satoshi-Bold.otf);
}

@font-face {
  font-family: 'Slussen Condensed';
  src: url(./assets/fonts/Slussen/Slussen-Condensed-Regular.otf);
}

@font-face {
  font-family: 'Slussen Condensed Bold';
  src: url(./assets/fonts/Slussen/Slussen-Condensed-Bold-Italic.otf);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style-type: none;
}

:root {
  font-family: vars.$defaultFont, vars.$secondOptionFont, sans-serif;
  font-size: vars.$rem;
  body {
    width: 100vw;
    background-color: vars.$background;
  }
}
