.frameWrapper {
  margin: auto;
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error {
  position: relative;
  width: 94px;
  height: 94px;
}

.mainText {
  width: 100%;
  position: relative;
  font-size: 2rem;
  line-height: 32px;
  display: inline-block;
  margin-bottom: 15px;
  margin-top: 15px;
  font-family: 'Slussen Condensed Bold';
  color: #1a3b66;
  text-align: center;
  }

.text {
  position: relative;
  line-height: 30px;
  font-family: Satoshi;
  font-size: 1.5rem;
  color: #2b4a75;
  width: 550px;
}