/* --------------------------------- colors --------------------------------- */
$background: #fff;
$danger: #dc3545;
$primary: #3b90ff;
$dark: #102a43;

/* ---------------------------------- text ---------------------------------- */
$defaultFont: "Okta Neue";
$rem: 16px;
$secondOptionFont: "Space Grotesk";
