.logos {
  position: absolute;
  height: 149.69px;
  width: 25%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: right;
  right: 0;
}
.blazepodIcon {
  position: relative;
  width: 6.2em;
  z-index: 1;
}

.blazepodContainer1 {
  margin-right: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.image11Icon {
  width: auto;
  height: 1.5em;
  max-width: 6.2em;
  align-self: center;
  margin: auto;
  overflow: hidden;
}

.companyLogo {
  width: 7em;
  height: 55%;
  align-self: center;
  background: radial-gradient(130.36% 182.37% at 5.98% 7.78%, rgba(49, 186, 244, 0.1) 0%, rgba(49, 186, 244, 0.00) 100%), 
  radial-gradient(99.26% 141.42% at 100.00% 0, rgba(68, 57, 226, 0.1) 0%, rgba(229, 234, 246, 0.00) 100%), 
  #F4F8FF; 
  box-shadow: 0px 0px 15px rgba(7, 33, 60, 0.06);
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  border-radius: 1rem 0px 0px 1rem;
}

.logosExmaples {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}


