.title {
  line-height: 47px;
  height: fit-content;
  width: 100%;
  font-size: 2.35rem;
  text-transform: uppercase;
  font-family: 'Slussen Condensed Bold';
  text-overflow: ellipsis;
  overflow: hidden;
}
.subtitle {
  font-size: 2rem;
  line-height: 36px;
  font-family: 'Outfit SemiBold';
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.titles {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 2em;
  width: 75%;
  justify-content: center;
}