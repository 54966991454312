.playerName {
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  mix-blend-mode: normal;
}
.name {
  position: relative;
  font-weight: 500;
  display: inline-block;
  width: 12em;
  flex-shrink: 0;
  mix-blend-mode: normal;
}