.stats {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}
.time {
  position: relative;
  font-weight: 500;
  display: inline-block;
  width: 140px;
  flex-shrink: 0;
  mix-blend-mode: normal;
}
.firstRow {
  flex: 1;
  border-radius: 6px 6px 0px 0px;
  background: radial-gradient(100% 50% at 50% 90%, rgba(15, 201, 231, 0.13), rgba(15, 201, 231, 0)), #fff;
  box-shadow: 0px 0px 15px rgba(7, 33, 60, 0.06);
  border-bottom: 2px solid #0fc9e7;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 10px 95px 10px 20px;
  margin-left: 10px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: flex-start;
}
.rank {
  position: relative;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  mix-blend-mode: normal;
  font-family: 'Outfit Thin';
  margin-bottom: 10px;
}
.generalRow {
  flex: 1;
  border-radius: 6px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0.85));
  box-shadow: 0px 0px 15px rgba(7, 33, 60, 0.06);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 10px 95px 10px 20px;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  margin-left: 10px;
  margin-bottom: 10px;
}
.leaderboardFirstRow {
  align-self: stretch;
  border-radius: 6px 6px 0px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}
.leaderboardGeneralRow {
  align-self: stretch;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.leaderboards1 {
  width: 100%;
  position: relative;
  border-radius: 6px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  color: #1a3b66;
}

.vectorIcon1 {
  position: relative;
  width: 17.15px;
  height: 17.34px;
  margin-top: 2px;
}

.icontrophy16px {
  border-radius: 30px;
  background-color: #0fc9e7;
  margin-bottom: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.table {
  width: 100%;
  max-height: 870px;
  overflow: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  font-size: 1.4rem;
  font-kerning: none;
  font-family: 'Outfit Light';
}

.leaderboardsEmpty {
  width: 100%;
  position: relative;
  border-radius: 6px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  color: rgba(26, 59, 102, 0.4);
}

