.boldText {
  width: 100%;
  position: relative;
  font-size: 2rem;
  line-height: 32px;
  display: inline-block;
  font-family: 'Slussen Condensed Bold';
  color: #1a3b66;
  text-align: center;
  margin-bottom: 10px;
}

.frameWrapper {
  margin-top: 10px;
  flex-direction: column;
}

.errorsHazzardParent {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 8rem;
  align-items: center;
  justify-content: center;
  color: #1a3b66;
  font-family: 'Slussen Condensed';
}