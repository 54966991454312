.header {
  width: 100%;
  position: relative;
  background: radial-gradient(circle at left, rgba(51, 43, 160, 0.5), rgba(51, 43, 160, 0)), 
              radial-gradient(circle at right, rgba(36, 137, 209, 0.5), rgba(36, 137, 209, 0)), 
              #07213c;
  height: 10rem;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 38px;
  color: #fff;
  }