.playerName {
  position: relative;
  display: inline-block;
  width: 5.7em;
  flex-shrink: 0;
  mix-blend-mode: normal;
  white-space: nowrap;
}
.player {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.stat {
  text-align: center;
  color: rgba(77, 116, 169, 0.7);
  white-space: nowrap;
  mix-blend-mode: normal;
}
.div {
  height: 24px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible; 
}
.stats {
  flex: 1;
  padding-right: 5em;
  padding-left: 15em;
  display: flex;
  justify-content: space-between;
}

.margins {
  width: 100%;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  color: rgba(77, 116, 169, 0.7);
}
.legend {
  width: 100%;
  position: relative;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  padding-left: 2.3em;
  font-size: 1.2rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 10px;
  color: rgba(77, 116, 169, 0.7);
  font-family: 'Outfit';
}

